<template>
    <div class="myFriend" :style="myFriend">
        <common-nav-bar title="我的朋友"></common-nav-bar>
        <van-cell-group inset class="fridendList m0" :style="fridendList">
            <van-cell
                class="bgNo"
                center
                title-class="totalTitle"
                value-class="integral"
                :title="'您邀请朋友总计：' + friends.length + '人'"
                :value="'积分' + friends.length * 100"
            />
            <van-cell
                v-for="(item, i) in friends"
                :key="i"
                class="bgNo"
                center
                title-class="friendName"
                value-class="registrationDate"
                :title="getName(item)"
                :value="'注册日期：' + formatDay(item.createdAt)"
            >
                <template #icon>
                    <van-image :src="item.wxHeadimgurl || userPhoto" width="48" />
                </template>
                <template #title>
                    <span>{{ getName(item) }}</span>
                    <van-icon :name="item.sex === 1 ? sexNan : sexNv" class="sex-icon" />
                    <van-icon v-if="item.hasDevice" :name="phone" class="phone-icon" />
                </template>
            </van-cell>
        </van-cell-group>
    </div>
</template>

<script>
import { getMyInviteUsers } from '@/services/user';
import { toast,formatDate } from '@/util';
import CommonNavBar from '@/components/common-nav-bar';

export default {
    // name: "index"
    components: { CommonNavBar },
    data() {
        return {
            myFriend: {
                backgroundImage: 'url(' + require('@/assets/images/myBgReverse.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                height: '100%',
                backgroundAttachment: 'fixed',
            },
            fridendList: {
                backgroundImage: 'url(' + require('@/assets/images/fridendListBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '50% 50%',
                backgroundPosition: '115px 35px',
            },
            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
            phone: require('@/assets/images/phoneIcon.png'),
            friends: [],
        };
    },
    created() {
        this.getMyInviteUsers();
    },
    methods: {
        async getMyInviteUsers() {
            const { code, message, data } = await getMyInviteUsers();
            if (!code) {
                return toast(message);
            }
            this.friends = data;
        },
        formatDay(day) {
            if (!day) {
                return '';
            }
            return formatDate(day, 'yyyy-MM-dd');
        },
        getName(user) {
            if (user.name != null && user.name.length > 0)  return user.name;
            return user.wxNickname;
        }
    },
};
</script>

<style scoped>
.myFriend {
    padding: 10px;
    box-sizing: border-box;
}

.totalTitle {
    font-size: 14px;
    color: #282a2d;
}

.integral {
    font-size: 14px;
    color: #58ae9f;
}

.fridendList .van-cell__left-icon {
    font-size: 48px;
    height: auto;
    line-height: 100%;
}

.friendName {
    font-size: 12px;
    color: #282a2d;
    margin-block: 10px;
}

.registrationDate {
    font-size: 10px;
    color: #868686;
}

.sex-icon,
.phone-icon {
    font-size: 14px;
    margin-left: 5px;
    vertical-align: middle;
}

.fridendList .van-cell__title,
.fridendList .van-cell__value {
    -webkit-box-flex: auto;
    -webkit-flex: auto;
    flex: auto;
}

.fridendList .van-image {
    font-size: 48px;
    height: auto;
    line-height: 100%;
    padding-right: 10px;
}
</style>
